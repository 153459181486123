const key = process.env.REACT_APP_CAPTCHA_KEY;


export const verifyGoogleToken = action => {

    const captchaKey = key.toString();

    window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute(captchaKey, {
                action: 'homepage'
            })
            .then(token => {
                action(token);
            });
    });
};

export const onChange = (fn) => ({ target: { value } }) => fn(value);

export const saveSession = (name, data) => window.sessionStorage.setItem([name], data);

export const pushGoogleTag = data => {
    window.dataLayer.push(data);
};

export const pushFBEvent = data => {
    if (window.fbq) {
        window.fbq('track', 'Purchase', data);
    }
};


export const gaUserId = () => {
    const id = document.cookie.match(/_ga=(.+?);/)[1];
    if (id) {
        return id.split('.')
            .slice(-2)
            .join('.');
    }
    return null;
};

export const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + '=([^;]*)'
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setAFCustomerId = userId => {
    if (userId) {
        const CUID = userId.toString();
        return window.AF('pba', 'setCustomerUserId', `'${CUID}'`);
    }

    return null;
};

export const checAuth = (data, setAuth) => {
    if (data.registration_source === 'email') {
        return setAuth({ account: 'email', data: data});
    }
    if (data.registration_source === 'facebook') {
        return setAuth({ account: 'facebook', data: data });
    }
    if (data.registration_source === 'google') {
        return setAuth({ account: 'google', data: data });
    }
};

export const toParam = (obj) => {
    const keys = Object.keys(obj);

    const str = keys.reduce((acc, item) => {
        return acc + item + '=' + obj[item] + '&';
    }, '?');

    return str;
};

export const PLANS = [
    {
        plan: '1-year-plan-apr',
        title: '3 Days Free Trial',
        braintreeName: 'gz_1_year_47_99_3d_trial',
        description: 'Then 12 months of GeoZilla Premium for',
        form: 'You won’t be charged until the Free Trial Period ends',
        payBtn: 'Start Free Trial',
        cost: 0,
        infoText: 'Activate trial',
        footer: 'Your 3-day free trial starts after you enter payment details, and you will have unlimited access to GeoZilla family safety features. You may cancel at any time during your free trial and will not be charged. To cancel, please write us at support@geozilla.com. GeoZilla will automatically continue your subscription at the end of your free trial and charge $47.99 to your payment method on an annual basis until you cancel.'
    },
    {
        plan: '1-month-plan-apr',
        title: 'GeoZilla Premium',
        braintreeName: 'gz_1_month_99_no_trial',
        description: '1 month for $14.99 billed monthly',
        form: '',
        payBtn: 'Subscribe',
        cost: 14.99,
        infoText: 'Activate subscription',
        footer: 'Your subscription starts after you enter payment details, and you will have unlimited access to GeoZilla family safety features. To cancel, please write us at support@geozilla.com. GeoZilla will automatically continue your subscription at the end of your subscription period and charge $14.99 to your payment method on monthly basis until you cancel.'
    },
];


export const getPlanSubscribe = () => {
    const plan = PLANS.find(i => window.location.search.includes(i.plan));
    if (plan) {
        return plan;
    }

    return PLANS[0];
};