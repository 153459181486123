import React, { Fragment, useContext, Suspense, useEffect, useState } from 'react';
import './App.scss';
import { Context } from './@context/Context';
import Loader from './Loader/Loader';
import {getPlanSubscribe} from './@helper/common/common';


const Header = React.lazy(() => import('./Header/Header'));
const PageTitle = React.lazy(() => import('./PageTitle/PageTitle'));
const Info = React.lazy(() => import('./Info/Info'));
const InfoSuccess = React.lazy(() => import('./Info/InfoSuccess'));
const CardForm = React.lazy(() => import('./CardForm/CardForm'));
const Login = React.lazy(() => import('./Login/Login'));
const BtnBuy = React.lazy(() => import('./BtnBuy/BtnBuy'));
const Feedback = React.lazy(() => import('./Feedback/Feedback'));
const Finish = React.lazy(() => import('./Finish/Finish'));
const Coupon = React.lazy(() => import('./Coupon/Coupon'));
const Alert = React.lazy(() => import('./Alert/Alert'));
const PayPal = React.lazy(() => import('./ExpressCheckout/PayPal'));
const currentAPI = process.env.REACT_APP_API_URL;




const renderStep = (subscribe, payPalFlow, captchaToken) => {


    if (subscribe) {
        return <Finish />;
    }

    const {plan} = getPlanSubscribe();

    return (
        <Fragment>
            <Loader/>
            <PageTitle />
            <Info />
            <InfoSuccess />
            <Login />
            {
                plan === '1-year-plan-apr' ? (<Coupon />) : null
            }
            {!payPalFlow && (<PayPal />)}
            {!payPalFlow && ( <CardForm captchaToken={captchaToken} />)}
            <BtnBuy />
            <Feedback />
        </Fragment>
    );

};

const App = () => {

    const { state: { lastStep, payPalFlow }} = useContext(Context);
    
    const [alert, setAlert] = useState(false);


    useEffect(() => {
        if (currentAPI.includes('stage')) {
            setAlert(true);
        }
    }, []);


    return (
        <Suspense fallback={<Loader/>}>
            <Fragment>
                <Header />
                {alert && <Alert/>}
                {
                    renderStep(lastStep, payPalFlow)
                }
            </Fragment>
        </Suspense>
    );
};

export default App;