const appId = process.env.REACT_APP_FB_APP_ID;
const state = process.env.REACT_APP_ACCOUNT_KIT_STATE;

const script = `
AccountKit_OnInteractive = function(){
    AccountKit.init(
      {
        appId:'${appId}', 
        state:'${state}',
        version:'v1.1',
        fbAppEventsEnabled: true,
      }
    );
  };
`;
const src = 'https://sdk.accountkit.com/en_US/sdk.js';

export default {
    script,
    src,
};


