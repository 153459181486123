const appId = process.env.REACT_APP_FB_APP_ID;

const FB = `
window.fbAsyncInit = function() {
    FB.init({
      appId      : '${appId}',
      cookie     : true,
      xfbml      : true,
      version    : 'v5.0',
      display    : 'modal', 
    });
      
    FB.AppEvents.logPageView();   
      
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));`;


export default FB;

