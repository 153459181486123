import React, { useContext } from 'react';
import './Loader.scss';
import { Context } from '../@context/Context';

const Loader = () => {

    const { state: { loader } } = useContext(Context);

    if (loader) {
        return (
            <div className="loader__body">
                <div className="loader__spinner"></div>
            </div>
        );
    }

    return null;
};

export default Loader;