import React, { useState, useEffect } from 'react';
import fetch from '../@helper/fetch/fetch.js';
import { checAuth, verifyGoogleToken } from '../@helper/common/common';
export const Context = React.createContext();

const Store = ({ children }) => {

    const [lastStep, setLastStep] = useState(false);
    const [cardName, setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [auth, setAuth] = useState({ account: '', data: null});
    const [authorized, setAuthorized] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState({ text: '', type: '', show: false, body: null });
    const [loader, setLoader] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [isApplied, setIsApplied] = useState(false);
    const [price, setPrice] = useState(47.99);
    const [customerId, setCustomerId] = useState('');
    const [newUser, setNewUser] = useState(true);
    const [passType, setPassType] =  useState('password');
    const [show, setShow] = useState(false);
    const [reset, setReset] = useState(false);
    const [payPayPalNonce, setPayPayPalNonce] = useState('');
    const [showCoupon, setShowCoupon] = useState(false);
    const [payPalFlow, setPayPalFlow] = useState(false);
    const [consent, setConsent] = useState(null);
    const [captchaTokenBraintree, setCaptchaTokenBraintree] = useState(null);
    const [captchaTokenSignup, setCaptchaTokenSignup] = useState(null);
    const [btToken, setBtToken] = useState(null);

    const getBraintreeToken = (url) => fetch.get(url)
    .then(({ data }) => setBtToken(data.client_token));

    useEffect(() => {
        if (window.sessionStorage.accessToken) {
            fetch.get('/users/me')
                .then(({ data: { entity } }) => checAuth(entity, setAuth))
                .catch(() => window.sessionStorage.removeItem('accessToken'));

        }
    }, [authorized]);

    useEffect(() => {
        setTimeout(() => verifyGoogleToken(setCaptchaTokenBraintree), 1000);
    }, []);

    useEffect(() => {
        if (captchaTokenBraintree) {
            const url = `/tracker-purchase/get-client-token?g-recaptcha-response=${captchaTokenBraintree}`;
            getBraintreeToken(url);
        }
    }, [captchaTokenBraintree])

    

    const logout = () => {
        fetch.get('/users/me/logout', {})
            .then(() => {
                setAuth({ account: '', data: null });
                setAuthorized(false);
                setNewUser(true);
                window.sessionStorage.removeItem('accessToken');
            })
            .catch((err) => {
                setError({ text: err.statusText, type: 'logout', show: true, body: err });
            });
    };



    return (
        <Context.Provider
            value={{
                state: {
                    lastStep,
                    cardName,
                    cardNumber,
                    expirationDate,
                    cvv,
                    postalCode,
                    auth,
                    email,
                    name,
                    error,
                    loader,
                    coupon,
                    price,
                    isApplied,
                    customerId,
                    password,
                    passType,
                    show,
                    reset,
                    newUser,
                    payPayPalNonce,
                    payPalFlow,
                    showCoupon,
                    authorized,
                    consent,
                    captchaTokenBraintree,
                    captchaTokenSignup,
                    btToken
                },
                actions: {
                    setLastStep,
                    setCardName,
                    setCardNumber,
                    setExpirationDate,
                    setCvv,
                    setPostalCode,
                    setAuth,
                    setEmail,
                    setName,
                    setError,
                    setAuthorized,
                    logout,
                    setCoupon,
                    setPrice,
                    setIsApplied,
                    setCustomerId,
                    setLoader,
                    setNewUser,
                    setPassword,
                    setPassType,
                    setShow,
                    setReset,
                    setPayPayPalNonce,
                    setPayPalFlow,
                    setShowCoupon,
                    setConsent,
                    setCaptchaTokenBraintree,
                    setCaptchaTokenSignup
                }
            }}
        >
            {
                children
            }
        </Context.Provider>

    );
};

export default Store;