import React, { Fragment, useEffect } from 'react';
import FB from './FB';
import GGL from './GGL';
import AcKIT from './AcKIT';
import PAYPAL from './PAYPAL';
import reCaptcha from './reCaptcha';

const useScript = (s) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = s;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [s]);
};

const useSrcScript = (src) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        // script.defer = true;
        script.src = src;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
};


const ScriptProvider = ({ children }) => {
    useSrcScript(reCaptcha);
    useSrcScript(PAYPAL);
    useScript(FB);
    useSrcScript(GGL.src);
    useScript(GGL.script);
    useSrcScript(AcKIT.src);
    useScript(AcKIT.script);



    return (
        <Fragment>
            {
                children
            }
        </Fragment>
    );
};

export default ScriptProvider;
