const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const checkResponse = (res) => {
    const isUploadFileWithNoCors = res.type === 'opaque';
    const { ok } = res;

    if (ok || isUploadFileWithNoCors) {
        return { res, };
    }

    const { status, statusText } = res;
    const err = {
        status,
        statusText
    };
    return { res, err };
};

const toJson = ({ res, err }) => new Promise((resolve) => {
    return res.json()
        .then(data => {
            resolve({
                err,
                data,
            });
        });
});

const checkErr = ({ data, err }) => {
    if (err) {
        let e = new Error(err.status);
        e.statusText = err.statusText;
        if (data) {
            e = Object.assign({}, { ...data });
        }
        throw e;

    }
    return data;
};

const fetchData = (url, method, body, headers) => {
    const defaultHeders = {
        'Content-Type': 'application/json'
    };

    if (window.sessionStorage.getItem('accessToken')) {
        Object.assign(defaultHeders, { Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}` });
    }
    const options = {
        method: method,
        headers: headers ? Object.assign(headers, defaultHeders) : defaultHeders
    };

    if (body) {
        Object.assign(options, { body: JSON.stringify(body) });
    }

    const apiUrl = REACT_APP_API_URL + url;

    return fetch(apiUrl, { ...options })
        .then(checkResponse)
        .then(toJson)
        .then(checkErr);
};

const instance = {
    get: async (url, headers) => await fetchData(url, 'GET', null, headers),
    post: async (url, body, headers) => await fetchData(url, 'POST', body, headers),
    patch: async (url, body, headers) => await fetchData(url, 'PATCH', body, headers),
    delete: async (url, headers) => await fetchData(url, 'DELETE', null, headers),
};

export default instance;